<template>
   <div>
    <CustomerProfile/>
    </div>
</template>

<script>
import CustomerProfile from "@/component/CustomerProfile/CustomerProfile.vue"
export default {
     name: 'ResturantMenuView',
        components: {
            CustomerProfile,
        }
}
</script>

<style >

 /* .c-body{
    background-color: black !important;
} */
</style>
