<template>
<div>
    <vue-snotify></vue-snotify>
    <CCard class="card-margin">
        <CRow class="justify-content-center">
            <CCol sm="12" md="6">
                <div class="p-5">
                    <h1 class="txt-color text-center pt-2">Profile</h1>
                    <ValidationObserver ref="request_form" v-slot="{ passes }">
                        <CForm>
                            <ValidationProvider vid="first_name" name="First Name" v-slot="{ errors }">
                                <CInput class="mt-5" addInputClasses="to-uppercase" placeholder="First name" v-model="first_name" v-uppercase />
                                <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                    <p v-for="(error, index) in errors" v-bind:key="index">
                                        {{ error }}
                                    </p>
                                </CAlert>
                            </ValidationProvider>

                            <ValidationProvider vid="last_name" name="Last Name" v-slot="{ errors }">
                                <CInput class="mt-5" addInputClasses="to-uppercase" placeholder="Last name" v-model="last_name" v-uppercase />
                                <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                    <p v-for="(error, index) in errors" v-bind:key="index">
                                        {{ error }}
                                    </p>
                                </CAlert>
                            </ValidationProvider>

                            <ValidationProvider vid="email" name="Email Address" v-slot="{ errors }">
                                <CInput class="mt-5" placeholder="EMAIL ADDRESS" v-model="email" v-lowercase />

                                <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                    <p v-for="(error, index) in errors" v-bind:key="index">
                                        {{ error }}
                                    </p>
                                </CAlert>
                            </ValidationProvider>

                            <CButton @click="updateCurrentUser" color="primary mt-5 mb-3 btn-size" block>
                                Update
                            </CButton>
                        </CForm>
                    </ValidationObserver>
                </div>
            </CCol>
        </CRow>
    </CCard>
</div>
</template>

    
    
<script>
import TheHeader from "../../containers/TheHeader.vue";
import TheFooter from "../../containers/TheFooter.vue";
import {
    mapState,
    mapActions
} from "vuex";
export default {
    name: "Page500",
    components: {
        TheHeader,
        TheFooter,
    },
    data() {
        return {
            isReset: 0,
            isLoading: false,
            fullPage: true,
            //form data
            first_name: "",
            last_name: "",
            email: "",
        };
    },
    computed: {},
    async mounted() {
        this.getCurrentLoggedCustomer().then((data) => {
            this.first_name = data.data.first_name;
            this.last_name = data.data.last_name;
            this.email = data.data.email;
            if (!data.data.error) {} else {

            }
        });
    },
    methods: {
        ...mapActions("user", [
            "getCurrentLoggedCustomer",
            "updateCurrentLoggedCustomer",
        ]),
        updateCurrentUser() {
            const params = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
            };
            this.updateCurrentLoggedCustomer(params).then((data) => {
                if (!data.data.error) {} else {
                    this.$refs.request_form.setErrors(data.data.error);
                }
            });
        },
    },
};
</script>
    
    
<style>
.form-control {
    border: 1px solid;
    color: #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
}

.c-main{
      background-color: rgba(0, 0, 0, 0.966);
}


</style>
<style scoped>
    .card-margin {
        background-color: rgb(0, 0, 0);
    }



    .heading-margin {
        padding-top: 12vh;
        padding-left: 5vw;
        box-sizing: border-box;
    }

    .heading-font-size {
        font-size: 3.2rem;
        color: #ffffff;
    }

    .heading-txt-color {
        color: #be70fd;
        font-size: 4rem;
    }

    .btn-size {
        height: 54px;
    }

    .txt-color {
        color: #ffffff;
    }

    @media (max-width: 768px) {
        .card-margin {
            background-color: rgba(0, 0, 0, 0.822);
            border: solid #ffffff00;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .col-md-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }

         .card-margin {
        background-color: rgba(0, 0, 0, 0.822);
    }
    }
</style>
